<template>
  <v-form @submit.prevent="submitResponse">
    <h2 v-if="task.content.config.heading" class="mb4">
      <stemble-latex :content="task.content.config.heading" style="white-space: pre-wrap" />
    </h2>
    <p class="body-1 mb-4 mt-4">
      <stemble-latex :content="task.content.config.question" style="white-space: pre-wrap" />
    </p>
    <s-textarea
      v-model="inputs.answer"
      outlined
      :counter="task.content.config.characterLimit > 0"
      :maxlength="
        task.content.config.characterLimit > 0 ? task.content.config.characterLimit : undefined
      "
    />
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'AiCriteriaQuestion',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        answer: '',
      },
    };
  },
};
</script>
